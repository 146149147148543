import { setAuth } from '@mint-lib/api';
import { AuthService, workspaceAuthStorage } from '@mint-lib/auth';

import type { MintServiceLocatorAbstractFactoryContext } from '../types.js';

export async function authFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
): Promise<AuthService> {
  const guestApi = await ctx.getInstance('http', 'guestApi' as const);
  const auth = new AuthService(guestApi, workspaceAuthStorage);
  // TODO: remove this line after migration to new api
  setAuth(auth);
  ctx.addEffect(
    auth.getEventEmitter().addChannel('auth', 'auth', ctx.eventBus),
  );
  ctx.addEffect(
    auth.getEventEmitter().addChannel('auth', 'logout', ctx.eventBus),
  );
  return auth;
}
