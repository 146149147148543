import { Box, Truncation, Typography } from '@myn/mui';

export type UserDataBlockProps = {
  label: string;
  data?: string | number;
};

const UserDataBlock = ({ label, data }: UserDataBlockProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(0.5),
      }}
    >
      <Typography
        variant="label01"
        color={(theme) => theme.palette.text.secondary}
      >
        {label}
      </Typography>

      <Truncation maxWidth={200} title={String(data)}>
        <Typography variant="bodyShort01">{data || '-'}</Typography>
      </Truncation>
    </Box>
  );
};

export default UserDataBlock;
