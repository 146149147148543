import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured')({
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/mui');
  },
  async beforeLoad({ context, location }) {
    if (!(await context.isAuthenticated())) {
      console.log('redirecting to login from secured route');
      throw redirect({
        to: '/login',
        search: {
          next: location.pathname,
        },
      });
    }

    if (
      !(await context.profileHelpers.isAgencyCompany()) &&
      !!location.pathname.includes('/agency-hub')
    ) {
      throw redirect({
        to: '/campaigns',
      });
    }

    if (
      (await context.profileHelpers.isAgencyCompany()) &&
      !location.pathname.includes('/agency-hub')
    ) {
      throw redirect({
        to: '/agency-hub',
      });
    }

    return context;
  },
});
