import {
  createFileRoute,
  NavigateOptions,
  notFound,
  redirect,
} from '@tanstack/react-router';

import { AxiosError } from '@mint-lib/api';

import { getImportFileLoader } from '@myn/import-hub/loaders';

type StepNames =
  | 'upload'
  | 'headers_mapping'
  | 'validation'
  | 'channel_assignment'
  | 'success';

const getStepRouteLink = (
  step: StepNames,
  params: {
    ingestionType: 'single-platform' | 'cross-platform';
    dataFileId: string;
  },
): NavigateOptions => {
  if (step === 'upload') {
    return {
      to: '/company-profile/import-hub/$ingestionType/imports/$dataFileId/upload',
      params,
    };
  }

  if (step === 'headers_mapping') {
    return {
      to: '/company-profile/import-hub/$ingestionType/imports/$dataFileId/headers-mapping',
      params,
    };
  }

  if (step === 'validation') {
    return {
      to: '/company-profile/import-hub/$ingestionType/imports/$dataFileId/validation',
      params,
      search: {
        onlyErrors: false,
        itemsPerPage: 50,
      },
    };
  }

  if (step === 'channel_assignment') {
    return {
      to: '/company-profile/import-hub/$ingestionType/imports/$dataFileId/channel-configuration',
      params,
      search: {
        itemsPerPage: 50,
      },
    };
  }

  return {
    to: '/company-profile/import-hub/$ingestionType',
    params,
    search: {
      tab: 'imports',
    },
  };
};

const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).name === 'AxiosError';
};

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType/imports/$dataFileId',
)({
  async beforeLoad({ context, params, location, buildLocation, preload }) {
    if (preload) {
      return {
        dataFilePreview: {} as Awaited<ReturnType<typeof getImportFileLoader>>,
      };
    }

    const hasModifyPermission = await context.hasPermission(
      'dataManagementHubFeature:modify',
    );
    if (!hasModifyPermission) {
      throw notFound();
    }

    const dataFilePreview = await getImportFileLoader({ params }).catch(
      (error) => {
        if (
          isAxiosError(error) &&
          (error.response?.status === 404 || error.response?.status === 422)
        ) {
          throw notFound();
        }
      },
    );

    const stepRouteLink = getStepRouteLink(
      dataFilePreview!.currentStep,
      params,
    );
    const stepRouteLocation = buildLocation(stepRouteLink);
    if (location.pathname !== stepRouteLocation.pathname) {
      throw redirect(stepRouteLink);
    }

    return { dataFilePreview };
  },
  shouldReload: false,
  loader: async (opts) => {
    return { dataFilePreview: opts.context.dataFilePreview || undefined };
  },
});
