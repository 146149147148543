import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType/imports/$dataFileId/channel-configuration',
)({
  validateSearch: z.object({
    itemsPerPage: z.number().catch(50),
  }),
});
