import React, { forwardRef, useContext } from 'react';

import TableM, { TableProps as TablePropsM } from '@mui/material/Table';

type SkeletonVariants = undefined | 'default' | 'checkbox' | 'avatar';

export interface TableProps extends TablePropsM {
  /**
   * The content of the component, normally TableRow.
   * @uxpinignoreprop
   * */
  children?: React.ReactNode;

  /**
   * Override or extend the styles applied to the component.
   * See CSS API below for more details.
   * @uxpinignoreprop
   */
  classes?: TablePropsM['classes'];

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;

  /**
   * Allows TableCells to inherit padding of the Table.
   */
  padding?: TablePropsM['padding'];
  /**
   * Allows TableCells to inherit size of the Table.
   */
  size?: TablePropsM['size'];
  /**
   * Set the header sticky.
   * ⚠️ It doesn't work with IE11.
   */
  stickyHeader?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details.
   */
  sx?: TablePropsM['sx'];
  isLoading?: boolean;
  placeholderRowsCount?: number;
}

type TableContextValue = {
  variants: SkeletonVariants[];
  isLoading: boolean;
  placeholderRowsCount: number;
  setVariants: (variants: SkeletonVariants[]) => void;
};

export const TableContext = React.createContext<null | TableContextValue>(null);

export const useTableContext = (): TableContextValue => {
  const tableContext = useContext(TableContext);

  if (!tableContext) {
    return {
      variants: [],
      isLoading: false,
      placeholderRowsCount: 10,
      setVariants: () => {},
    } as TableContextValue;
  }
  return tableContext;
};

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ isLoading = false, placeholderRowsCount = 10, ...props }, ref) => {
    const [variants, setVariants] = React.useState<SkeletonVariants[]>([]);

    return (
      <TableContext.Provider
        value={{
          variants: variants,
          setVariants,
          isLoading,
          placeholderRowsCount,
        }}
      >
        <TableM ref={ref} {...props}>
          {props.children}
        </TableM>
      </TableContext.Provider>
    );
  },
);

export default Table;
