import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import { useFlags } from '@mint-lib/flags';
import { useDebounce } from '@mint-lib/hooks';
import { useTranslation } from '@mint-lib/i18n';
import {
  ClientType,
  FeaturePermissionsConstants,
  useIsAgencyCompany,
  usePermissions,
  useProfile,
} from '@mint-lib/profile-helpers';

import {
  Alert,
  BaseFile,
  Box,
  Dialog,
  Divider,
  FileUploaderV2,
  OptionType,
  Select,
  SelectWithSearch,
  Typography,
} from '@myn/mui';

import { useAgencyClients } from '../../api/queries/agencyClients.js';
import { useUserRole } from '../../api/queries/userRoles.js';
import { useUsers } from '../../api/queries/usersData.js';
import { RoleType } from '../../api/roles/index.js';
import { UserModalFormType, UserType } from '../../api/users/index.js';
import ConditionalSkeleton from '../../atoms/ConditionalSkeleton/ConditionalSkeleton.jsx';
import FileUploaderPreview, {
  FilePreview,
} from '../../atoms/FileUploaderPreview/FileUploaderPreview.jsx';
import FormBlock from '../../atoms/FormBlock/FormBlock.jsx';
import InputField from '../../atoms/InputField/InputField.jsx';
import { Trans } from '../../molecules/Trans/index.js';
import { nullSafeFn } from '../../utils/index.js';
import UserModalNew from '../UserModalNew/index.jsx';
import type { UserModalViewType } from '../UserModalNew/userModal.types.js';
import { maxSizeBytes } from './helpers.js';
import { HandleSubmitPramsType } from './userModal.types.js';
import { validationSchema } from './validation.js';

export const filterAgencyRoles = (
  list: RoleType[],
  isAgencyUser: boolean,
  ff: boolean,
): RoleType[] => {
  return ff
    ? isAgencyUser
      ? list
      : list.filter((role) => role.roleType !== 'agency_client_role_type')
    : list;
};

export const mapRolesToSelect = (list: RoleType[]): OptionType[] =>
  list.map((option) => ({
    value: option?.id,
    label: option?.name,
  }));

export const mapClientsToSelect = (list: ClientType[]): OptionType[] =>
  list.map((option) => ({
    value: option?.id,
    label: option?.name,
  }));

export const SUPPORTED_AVATAR_FORMATS = ['.jpeg', '.png', '.gif', '.jpg'];

type Props = {
  className?: string;
  onClose: () => void;

  onSubmit: (
    params: HandleSubmitPramsType,
  ) => Promise<UserType | null | undefined>;
  initialValues?: Partial<UserType>;
  isActive: boolean;
  isReadonlyPermission: boolean;
  isUserExistInWorkspace?: boolean;
  setIsUserExistInWorkspace?: (value: boolean) => void;
  initialView?: UserModalViewType;
};

const UserModal = ({
  onClose,
  onSubmit,
  initialValues = {},
  isActive,
  isReadonlyPermission,
  isUserExistInWorkspace = undefined,
  setIsUserExistInWorkspace,
  initialView,
}: Props) => {
  const { t } = useTranslation('@myn/permissions');

  const { profile } = useProfile();

  const { getPermissionField } = usePermissions();
  const ff = useFlags([
    'fixValidationOnUserModal',
    'displayAssignedClientForCurrentUser',
    'agencyRoleOnlyForAgencyUsers',
    'newUserModal',
  ]);

  const isAgencyCompany = useIsAgencyCompany();

  const { agencyClients } = useAgencyClients();

  const { checkUserByEmail } = useUsers({ disableUsersFetch: true });

  const [role, setRole] = useState<number | null>(null);
  const [assignedClients, setAssignedClients] = useState<number[] | null>(null);

  // need store email in the component state for debounced search
  const [emailValue, setEmailValue] = useState<string | null>(null);

  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);

  const [uploadedFile, setUploadedFile] = useState<BaseFile[]>([]);

  const {
    userRoleData,
    userRole: { isLoading: userRoleLoading },
  } = useUserRole({ getFullList: true });

  const roleOptions = useMemo(
    () =>
      mapRolesToSelect(
        filterAgencyRoles(
          (userRoleData as RoleType[]) || [],
          initialValues.agencyUser ?? false,
          ff.agencyRoleOnlyForAgencyUsers.enabled,
        ),
      ),
    [userRoleData, ff.agencyRoleOnlyForAgencyUsers],
  );

  const isLoading = userRoleLoading || !roleOptions?.length;

  const clientsOptions = nullSafeFn<ClientType[] | null, OptionType[]>(
    mapClientsToSelect,
    agencyClients,
  );

  const mapAvatarPreviewToFilePreviewType: FilePreview = useMemo(
    () => ({
      name: avatarPreview?.split(`/avatars/`)[1]?.split(`?`)[0] || '',
      preview: avatarPreview || '',
    }),
    [avatarPreview],
  );

  const handleSubmitFom: SubmitHandler<Partial<UserType>> = (values) => {
    const { firstName, lastName, email, mobileNumber, officeNumber } = values;

    if (!role || !email) {
      return;
    }

    onSubmit({
      userData: {
        firstName,
        lastName,
        email,
        mobileNumber,
        officeNumber,
        role: role,
        avatar: uploadedFile[0],
        deleteAvatar: !uploadedFile[0] && !avatarPreview,
      },
      isUserExist: !!isUserExist,
      clients: assignedClients ?? [],
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, ...restFormState },
    setValue,
    trigger,
    getValues,
    control,
    reset,
    ...methods
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
  });

  const isAllRequiredFieldsFilled = !!getValues().email?.length && !!role;
  const hasInitialValues = Object.keys(initialValues).length > 0;

  useEffect(() => {
    if (!roleOptions) {
      return;
    }

    // TODO: change response on backend to get role ID in user response
    const initialRole =
      (roleOptions?.find((item) => item.label === initialValues.role)
        ?.value as number) ?? null;

    setRole(initialRole);
    setAssignedClients(initialValues.assignedClients ?? null);

    setValue('role', initialRole);

    setValue('assignedClients', initialValues.assignedClients);

    setAvatarPreview(initialValues?.avatar ?? null);
  }, [roleOptions]);

  const [isUserExist, setIsUserExist] = useState<boolean | null>(null);

  const debouncedSearch = useDebounce(emailValue, 800);

  const handleEmailChange = (email: string) => {
    if (errors?.email) {
      return;
    }

    if (!email) {
      setIsUserExist(null);
      setAvatarPreview(null);

      return;
    }

    checkUserByEmail.mutateAsync(email, {
      onSettled: () => {},
      onSuccess: (data) => {
        setIsUserExist(true);
        reset({ ...data, email });
        setAvatarPreview(data?.avatar || '');
        setRole(null);
      },
      onError: () => {
        //  if (isUserExist) to prevent reset form if user creates new
        // user and changes email field
        if (isUserExist) {
          reset({
            lastName: '',
            firstName: '',
            avatar: null,
            role: '',
            mobileNumber: '',
            officeNumber: '',
            email,
          });
          setRole(null);
          setAvatarPreview(null);
        }

        setIsUserExist(false);
      },
    });
  };

  // useDebounce to handle email check on email change
  useEffect(() => {
    // debouncedSearch can return empty string  thats why we
    // need to check it and call only if its not empty
    // to handle for reset in handleEmailChange
    // also we should skip email_check on edit

    !hasInitialValues && debouncedSearch && handleEmailChange(debouncedSearch);
  }, [debouncedSearch]);

  //  fields should be disable until we check that user exists
  //  until check we should keep it as null value
  //  if exits we should disable fields
  //  if user clear email field we should disable fields
  //  if user has validation error we should disable fields
  const disabledByUser = useMemo(() => {
    return (
      isUserExist ||
      isUserExist === null ||
      !getValues('email') ||
      !!errors?.email
    );
  }, [isUserExist, getValues('email'), errors?.email]);

  const getPrimaryButtonLabel = useMemo(() => {
    if (hasInitialValues) {
      return t('Edit User');
    }

    if (isUserExist) {
      return t('Invite');
    }

    return t('Add User');
  }, [hasInitialValues, isUserExist]);

  const renderAlert = () => {
    // when User is deactivated in Admin Panel and has no access to MINT platform at all show warning
    if (getValues('isActive') === false && !errors.email?.message) {
      return (
        <Alert
          title={t('This user is deactivated.')}
          severity="warning"
          subTitle={
            <Trans
              i18nKey={`Reach out to Mint ARM support <emailLink>{{email}}</emailLink> if you need to re-activate the user.`}
              t={t}
              values={{ email: t(`support@mint.ai`) }}
            />
          }
        />
      );
    }

    if (!hasInitialValues && !isAgencyCompany) {
      return (
        <Alert
          title={t(
            'Remember to assign “Brand & Geographies” to New users or they won’t see any campaign.',
          )}
          severity="info"
          subTitle={t(
            `You can do so from the “Brand & Geographies” section of the “Company Profile” page.`,
          )}
        />
      );
    }

    return null;
  };

  return ff.newUserModal.enabled ? (
    <UserModalNew
      onClose={onClose}
      // TODO fix type after release
      onSubmit={onSubmit as any}
      initialValues={initialValues as UserModalFormType}
      isActive={isActive}
      isReadonlyPermission={isReadonlyPermission}
      initialView={initialView}
    />
  ) : (
    <FormProvider
      {...{
        ...methods,
        register,
        handleSubmit,
        formState: { errors, ...restFormState },
        setValue,
        trigger,
        getValues,
        control,
        reset,
      }}
    >
      <Dialog
        open={isActive}
        onClose={onClose}
        title={hasInitialValues ? t('Edit user') : t('New user')}
        size="m"
        submitButtonProps={{
          label: getPrimaryButtonLabel,
          onClick: handleSubmit(handleSubmitFom),
          disabled:
            !!Object.keys(errors)?.length ||
            !isAllRequiredFieldsFilled ||
            isReadonlyPermission ||
            getValues('isActive') === false,

          color: 'primary',
        }}
        cancelButtonProps={{
          label: t('Cancel'),
          onClick: onClose,
          variant: 'text',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: (theme) => theme.spacing(2),
          }}
        >
          {renderAlert()}
          <ConditionalSkeleton isLoading={isLoading} width={648} height={60}>
            <InputField
              label={t('Email*')}
              placeholder={t('Enter email')}
              disabled={hasInitialValues || isReadonlyPermission}
              error={!!errors.email?.message || !!isUserExistInWorkspace}
              helperText={
                errors.email?.message ||
                (isUserExistInWorkspace
                  ? t('This user is already in the workspace')
                  : undefined)
              }
              onFocus={() => {
                if (setIsUserExistInWorkspace) {
                  setIsUserExistInWorkspace(false);
                }
              }}
              onChange={(e) => {
                // Without the next line, if the field is auto-filled by the browser, react-form does not know it
                setEmailValue(e.target.value);
                setValue('email', e.target.value);

                if (!ff.fixValidationOnUserModal.enabled) {
                  trigger('email');
                }
              }}
              name="email"
            />
          </ConditionalSkeleton>
          <ConditionalSkeleton isLoading={isLoading} width={648} height={60}>
            <FormBlock>
              <Box sx={{ flexGrow: '1' }}>
                <Select
                  label={t('Role*')}
                  placeholder={t('Select role')}
                  options={roleOptions}
                  value={role || ''}
                  disabled={
                    profile?.id === initialValues?.id || isReadonlyPermission
                  }
                  {...register('role', { required: true })}
                  onChange={(e) => {
                    e && setRole(e);

                    setValue('role', e);
                    trigger('role');
                  }}
                  fullWidth
                />
              </Box>
            </FormBlock>
          </ConditionalSkeleton>
          <FormBlock>
            <ConditionalSkeleton isLoading={isLoading} width={320} height={60}>
              <InputField
                label={t('First name*')}
                placeholder={t('Enter first name')}
                disabled={isReadonlyPermission || disabledByUser}
                name="firstName"
              />
            </ConditionalSkeleton>
            <ConditionalSkeleton isLoading={isLoading} width={320} height={60}>
              <InputField
                label={t('Last name*')}
                placeholder={t('Enter last name')}
                disabled={isReadonlyPermission || disabledByUser}
                name="lastName"
              />
            </ConditionalSkeleton>
          </FormBlock>

          <FormBlock>
            <ConditionalSkeleton isLoading={isLoading} width={320} height={60}>
              <InputField
                label={t('Mobile umber')}
                placeholder={t('Enter mobile number')}
                disabled={isReadonlyPermission || disabledByUser}
                name="mobileNumber"
              />
            </ConditionalSkeleton>
            <ConditionalSkeleton isLoading={isLoading} width={320} height={60}>
              <InputField
                label={t('Office number')}
                placeholder={t('Enter office number')}
                disabled={isReadonlyPermission || disabledByUser}
                name="officeNumber"
              />
            </ConditionalSkeleton>
          </FormBlock>

          {isAgencyCompany ? (
            <FormBlock>
              <ConditionalSkeleton
                isLoading={isLoading}
                width={648}
                height={60}
              >
                <Box sx={{ flexGrow: '1', maxWidth: '648px' }}>
                  <SelectWithSearch
                    label={t('Clients')}
                    placeholder={t('Assign clients')}
                    truncatedOptions
                    multiple
                    infoText={t(`Assign clients to this user`)}
                    TooltipProps={{ placement: 'top' }}
                    options={clientsOptions ?? []}
                    value={assignedClients ?? []}
                    disabled={
                      ff.displayAssignedClientForCurrentUser.enabled
                        ? isReadonlyPermission
                        : profile?.id === initialValues?.id ||
                          isReadonlyPermission ||
                          getPermissionField('agencyClientFeature') !==
                            FeaturePermissionsConstants.FULL_CONTROL
                    }
                    {...register('assignedClients')}
                    onChange={(e) => {
                      e && setAssignedClients(e);

                      setValue('assignedClients', e);
                      trigger('assignedClients');
                    }}
                    fullWidth
                  />
                </Box>
              </ConditionalSkeleton>
            </FormBlock>
          ) : null}
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: (theme) => theme.spacing(2),
            }}
          >
            <ConditionalSkeleton isLoading={isLoading} width={250} height={120}>
              <Typography variant="h6">{t('User Avatar')}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: (theme) => theme.spacing(1),
                }}
              >
                <FileUploaderV2
                  maxFiles={1}
                  onChange={(file) => {
                    setUploadedFile(file);
                  }}
                  onUpload={() =>
                    // workaround to mock promise for FileUploaderV2
                    // will be removed when onUpload will be optional
                    Promise.resolve()
                  }
                  variant="button"
                  disabled={disabledByUser}
                  maxSize={maxSizeBytes}
                  accept={SUPPORTED_AVATAR_FORMATS}
                  renderItem={(file) => {
                    return (
                      <FileUploaderPreview
                        file={{
                          name: file.baseFile.name,
                          preview: file.baseFile.previewUrl || '',
                        }}
                        onDeleteClick={() => {
                          file.onRemove();
                        }}
                        disabled={disabledByUser}
                      />
                    );
                  }}
                />
              </Box>
            </ConditionalSkeleton>
          </Box>
          {avatarPreview && (
            <Box sx={{ display: 'flex' }}>
              <FileUploaderPreview
                file={mapAvatarPreviewToFilePreviewType}
                onDeleteClick={() => setAvatarPreview(null)}
                disabled={disabledByUser}
              />
            </Box>
          )}
        </Box>
      </Dialog>
    </FormProvider>
  );
};

export default UserModal;
