import { useFlags } from '@mint-lib/flags';

import { Box, Chip, Truncation } from '@myn/mui';

import { UserType } from '../../../api/users/index.js';

export type Props = UserType & {
  onClick: (user: UserType) => void;
};

const LabelCell = ({ onClick, ...value }: Props) => {
  const ff = useFlags(['smallPermissionsTableFixes']);

  const labels = value?.tags || [];
  return (
    <Box
      sx={{
        display: 'flex',
        whiteSpace: 'nowrap',
        gap: (theme) => theme.spacing(1),
      }}
    >
      {labels[0] && ff.smallPermissionsTableFixes.enabled ? (
        <Truncation sx={{ maxWidth: '200px' }} title={labels[0].name}>
          <Chip label={labels[0].name} />{' '}
        </Truncation>
      ) : null}

      {labels[0] && !ff.smallPermissionsTableFixes.enabled ? (
        <Chip label={labels[0].name} />
      ) : null}

      {labels.length > 1 && (
        <Chip label={`+${labels.length - 1}`} onClick={() => onClick(value)} />
      )}
    </Box>
  );
};

export default LabelCell;
