import { useIsAgencyCompany } from '@mint-lib/profile-helpers';
import { useSearch } from '@mint/routing';

const useConditionalSearch = () => {
  const isAgencyRoute = useIsAgencyCompany();

  return useSearch({
    from: isAgencyRoute
      ? '/_secured/agency-hub/users-management'
      : '/_secured/company-profile/users-management',
  });
};
export default useConditionalSearch;
