import React, { forwardRef } from 'react';

import styled from '@mint-lib/styled';
import { CheckboxProps } from '@mui/material/Checkbox';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';

import FormControl from '../FormControl/FormControl.jsx';
import FormLabel from '../FormLabel/FormLabel.jsx';

enum VariantType {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface CheckboxGroupProps extends FormGroupProps {
  /**
   * Display text over the radio group.
   */
  groupLabel?: string;
  /**
   * The position of the label.
   */
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  /**
   * The value of the initially selected radio button.

   */
  value?: string;
  /**
   * The content of the component.
   * @uxpinignoreprop
   */
  children?: React.ReactNode;
  /**
   * The size of the component. small is equivalent to the dense checkbox styling.
   */
  size?: CheckboxProps['size'];
  color?: CheckboxProps['color'];
  /**
   * The name used to reference the value of the control.
   * @uxpinignoreprop
   */
  name?: string;
  /**
   * @uxpinignoreprop
   */
  onKeyDown: FormGroupProps['onKeyDown'];
  /**
   * display selection controls in a single row.
   */
  row?: boolean;
  /**
   * Change event to use with UXPin interactions.
   */
  onChange?: FormGroupProps['onChange'];
  /**
   * If `true`, the checkbox is checked.
   * @uxpinbind onChange 1
   */
  checked?: boolean;
  /**
   * Align variants of the checkboxes can be either vertical or horizontal
   */
  variant?: VariantType;
}

const CheckboxGroup = forwardRef((props: CheckboxGroupProps, ref) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.groupLabel}</FormLabel>
      <StyledFormGroup variant={props.variant} ref={ref}>
        {props.children}
      </StyledFormGroup>
    </FormControl>
  );
});

export default CheckboxGroup;

const StyledFormGroup = styled(FormGroup)<{
  variant?: VariantType;
}>(({ variant }) => ({
  flexWrap: 'nowrap',
  flexDirection: variant === VariantType.Horizontal ? 'row' : 'column',
}));

CheckboxGroup.defaultProps = {
  onChange: () => undefined,
};
