/* USERS */
export const USERS = '/api/authentication/v1/users/';
export const RESEND_USER_INVITATION =
  '/api/authentication/v1/users/send-invitation/';
export const SWITCH_USER_STATUS = '/api/authentication/v1/users/switch-status/';

/* USER ROLES */
export const USER_ROLES = '/api/authentication/v1/roles/';

/* USER LABELS */
export const LABEL_CATEGORIES = '/api/authentication/v1/categories/';
export const LABELS = '/api/authentication/v1/labels/';

/* USER EMAIL CHECK */
export const CHECK_EMAIL = '/api/authentication/v1/users/check-email/';

/* AGENCY */
export const AGENCY_CLIENTS = '/api/authentication/v1/agency_clients/';
