import { useCallback, type Dispatch, type SetStateAction } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';
import {
  FeaturePermissionsConstants,
  useIsAgencyCompany,
  usePermissions,
} from '@mint-lib/profile-helpers';

import { Box, OptionType, Select, SelectWithSearch } from '@myn/mui';

import { UserModalFormType } from '../../api/users/users.types.js';
import ConditionalSkeleton from '../../atoms/ConditionalSkeleton/ConditionalSkeleton.jsx';
import FormBlock from '../../atoms/FormBlock/index.js';
import InputField from '../../atoms/InputField/InputField.jsx';

export type NonExistingUserView = {
  formInstance: UseFormReturn<UserModalFormType>;
  isReadonlyPermission: boolean;
  roleOptions: OptionType[];
  clientsOptions: OptionType[] | null;
  assignedClients: number[] | null;
  setAssignedClients: Dispatch<SetStateAction<number[] | null>>;
  isLoading: boolean;
};

const NonExistingUserView = ({
  formInstance,
  isReadonlyPermission,
  roleOptions,
  clientsOptions,
  assignedClients,
  setAssignedClients,
  isLoading,
}: NonExistingUserView) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags(['displayAssignedClientForCurrentUser']);

  const { getPermissionField } = usePermissions();

  const isAgencyCompany = useIsAgencyCompany();

  const handleRoleChange = useCallback(
    (e: number | '') => {
      formInstance.setValue('role', e, { shouldDirty: true });
      formInstance.trigger('role');
    },
    [formInstance],
  );

  const handleAssignedClientsChange = useCallback(
    (e: number[]) => {
      e && setAssignedClients(e);

      formInstance.setValue('assignedClients', e, {
        shouldDirty: true,
      });

      formInstance.trigger('assignedClients');
    },
    [formInstance, setAssignedClients],
  );

  return (
    <>
      <ConditionalSkeleton height={60} width={488} isLoading={isLoading}>
        <InputField
          label={t('Email*')}
          placeholder={t('Enter email')}
          error={!!formInstance.formState.errors.email?.message}
          helperText={formInstance.formState.errors.email?.message}
          onChange={(e) => {
            formInstance.setValue('email', e.target.value);
          }}
          name="email"
          disabled
        />
      </ConditionalSkeleton>

      <FormBlock>
        <ConditionalSkeleton height={60} width={240} isLoading={isLoading}>
          <InputField
            label={t('First name*')}
            placeholder={t('Enter first name')}
            disabled={isReadonlyPermission}
            name="firstName"
          />
        </ConditionalSkeleton>
        <ConditionalSkeleton height={60} width={240} isLoading={isLoading}>
          <InputField
            label={t('Last name*')}
            placeholder={t('Enter last name')}
            disabled={isReadonlyPermission}
            name="lastName"
          />
        </ConditionalSkeleton>
      </FormBlock>

      <FormBlock>
        <Box sx={{ flexGrow: '1' }}>
          <ConditionalSkeleton height={60} width={488} isLoading={isLoading}>
            <Select
              truncatedOptions
              label={t('Role*')}
              placeholder={t('Select role')}
              options={roleOptions}
              value={Number(formInstance.getValues('role')) || ''}
              disabled={isReadonlyPermission}
              infoText={t(`This role will be applied to the agency workspace.`)}
              TooltipProps={{ placement: 'top' }}
              {...formInstance.register('role', { required: true })}
              error={Boolean(formInstance.formState.errors.role?.message)}
              helperText={formInstance.formState.errors.role?.message}
              onChange={handleRoleChange}
              fullWidth
            />
          </ConditionalSkeleton>
        </Box>
      </FormBlock>

      {isAgencyCompany ? (
        <FormBlock>
          <Box sx={{ flexGrow: '1', maxWidth: '488px' }}>
            <ConditionalSkeleton height={60} width={488} isLoading={isLoading}>
              <SelectWithSearch
                truncatedOptions
                label={t('Clients')}
                placeholder={t('Assign clients')}
                multiple
                infoText={t(`Assign clients to this user`)}
                TooltipProps={{ placement: 'top' }}
                options={clientsOptions ?? []}
                value={assignedClients ?? []}
                disabled={
                  ff.displayAssignedClientForCurrentUser.enabled
                    ? isReadonlyPermission
                    : isReadonlyPermission ||
                      getPermissionField('agencyClientFeature') !==
                        FeaturePermissionsConstants.FULL_CONTROL
                }
                {...formInstance.register('assignedClients')}
                error={Boolean(
                  formInstance.formState.errors.assignedClients?.message,
                )}
                helperText={
                  formInstance.formState.errors.assignedClients?.message
                }
                onChange={handleAssignedClientsChange}
                fullWidth
              />
            </ConditionalSkeleton>
          </Box>
        </FormBlock>
      ) : null}
    </>
  );
};

export default NonExistingUserView;
