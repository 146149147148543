import { ChangeEvent, useCallback, useMemo, useRef } from 'react';

import { TFunction, useTranslation } from '@mint-lib/i18n';
import { useIsAgencyCompany, usePermissions } from '@mint-lib/profile-helpers';
import { useNavigate } from '@mint/routing';
import { useDebouncedCallback } from 'use-debounce';

import { Box, Button, SearchBar, Tab, Tabs, Typography } from '@myn/mui';

import { PERMISSIONS_MAP } from '../../constants.js';
import { useModal } from '../../context/ModalContext/useModal.js';
import useConditionalSearch from '../../hooks/useConditionalSearch/useConditionalSearch.js';
import useTab, { RouteTabs } from '../../hooks/useTab/useTab.js';

export const CreateModalLabelMap: (
  t: TFunction,
) => Record<RouteTabs, string> = (t: TFunction) => ({
  [RouteTabs.User]: t('New user'),
  [RouteTabs.Role]: t('New role'),
});

const Filters = () => {
  const { t } = useTranslation('@myn/permissions');

  const isAgencyRoute = useIsAgencyCompany();

  const { setOpen: setIsNewModalOpened } = useModal();

  const navigate = useNavigate();

  const { search } = useConditionalSearch();

  const ref = useRef<HTMLInputElement | null>(null);

  const activeRoute = useTab();

  const { getPermissionField } = usePermissions();

  const isAgencyCompany = useIsAgencyCompany();

  const isReadonlyPermission = useMemo(
    () =>
      getPermissionField('usersPermissionsFeature') === PERMISSIONS_MAP.View,
    [getPermissionField('usersPermissionsFeature')],
  );

  const label = CreateModalLabelMap(t)[activeRoute ?? RouteTabs.User];

  const handleChange = useCallback(
    (searchQuery?: string) => {
      navigate({
        search: () => ({ search: searchQuery }),
      });
    },
    [navigate],
  );

  const onChangeDebounced = useDebouncedCallback(handleChange, 300);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(2),
        mr: '2px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '2px',
        }}
      >
        <Typography variant="h3">{t('Users Management')}</Typography>
        {isAgencyCompany && activeRoute === 'role' ? null : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: (theme) => theme.spacing(2),
              mr: '2px',
            }}
          >
            <SearchBar
              innerRef={ref}
              size="medium"
              defaultValue={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeDebounced(e.target.value || undefined);
              }}
              onClear={() => {
                handleChange(undefined);

                if (ref.current) {
                  ref.current.value = '';
                }
              }}
              placeholder={`${t('Search')}`}
              sx={{ width: '300px' }}
            />
            {isReadonlyPermission ? null : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setIsNewModalOpened(true)}
                startIcon={'Add'}
              >
                {label}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Tabs
        value={activeRoute}
        indicatorPosition="bottom"
        orientation="horizontal"
      >
        <Tab
          label={t('Users')}
          value={'user'}
          onClick={() => {
            navigate({
              to: isAgencyRoute
                ? '/agency-hub/users-management/user'
                : '/company-profile/users-management/user',
            });
          }}
        />
        <Tab
          label={t('Roles')}
          value={'role'}
          onClick={() => {
            navigate({
              to: isAgencyCompany
                ? '/agency-hub/users-management/role'
                : '/company-profile/users-management/role',
            });
          }}
        />
      </Tabs>
    </Box>
  );
};

export default Filters;
